import { Form, useLoaderData, useNavigation } from "@remix-run/react";
import loader from "./loader";
import Text from "~/components/Text";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { parseWithZod } from "@conform-to/zod";
import { schema } from "./form";
import OfficeLogo from "~/routes/_client.identite-bancaire.$bankDetailsRequestReference.confirmer/components/OfficeLogo";

export default function () {
  const loaderData = useLoaderData<typeof loader>();
  const navigation = useNavigation();
  const shouldDisableButton = navigation.state === "submitting";
  const [form, fields] = useForm({
    id: "connection-form",
    onValidate({ formData }) {
      return parseWithZod(formData, { schema });
    },
  });

  return (
    <>
      <Stack
        justifyContent="space-between"
        sx={{ flexGrow: 1 }}
        alignItems="center"
        py={11}
        spacing={3}
      >
        <OfficeLogo />
        <Stack spacing={2} alignItems="center">
          <Typography variant={"h6"} textAlign={"center"}>
            <Text textKey={"first_auth_title"} />
          </Typography>
          <Box maxWidth={"400px"}>
            <Typography variant={"subtitle1"} textAlign={"center"}>
              {loaderData.officeName ? (
                <Text
                  textKey={"first_auth_subtitle"}
                  variables={{ OFFICE_NAME: loaderData.officeName }}
                />
              ) : (
                <Text textKey={"first_auth_subtitle_incognito"} />
              )}
            </Typography>
          </Box>
        </Stack>

        <Form
          {...getFormProps(form)}
          method={"POST"}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Stack spacing={3} alignItems={"center"}>
            <Stack spacing={1} alignSelf={"center"}>
              <Typography>
                <Text textKey={"first_auth_email_field_label"} />
              </Typography>
              <TextField
                {...getInputProps(fields.email, { type: "text" })}
                name={fields.email.name}
                defaultValue={loaderData.email}
                error={!!fields.email.errors}
              />
            </Stack>
            <Button
              type={"submit"}
              variant="contained"
              disabled={shouldDisableButton}
            >
              <Text textKey={"first_auth_button"} />
            </Button>
          </Stack>
        </Form>
      </Stack>
    </>
  );
}
